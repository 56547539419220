import React from "react";
import styled from "styled-components";
import {useStore} from "modules/hooks";

const Wrapper = styled.div`
	color: ${({theme}) => theme.color.footer};
	background-color: ${({theme}) => theme.background.footer};
	padding: 4rem 2rem;
	display: block;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: 4rem 8rem;
	}
`;

const Text = styled.div`
	font-family: "Teko", sans-serif;
	font-size: 1.2rem;
	letter-spacing: 0.03em;
	margin: 0;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 1.3rem;
	}
`;

const Logo = styled.img`
	width: 80px;
	margin-bottom: 0.5rem;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 100px;
	}
`;

const Links = styled.div`
	display: flex;
	gap: 16px;
	margin-top: 1rem;
`;

const Link = styled.a`
	width: 45px;
	height: 45px;
	border: 1px solid #fff;
	display: flex;
	align-items: center;
	justify-content: center;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 55px;
		height: 55px;
	}
`;

const Icon = styled.img`
	width: 55%;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 75%;
	}
`;

export const Footer: React.FC = () => {
	const {I18n} = useStore();

	return (
		<Wrapper>
			<Logo src="https://clients2.titanium6.com/pfl-footer/img/pfl-logo-white.svg" />
			<Text>{I18n.t("footer.copyright")}</Text>
			<Text>{I18n.t("footer.follow")}</Text>
			<Links>
				<Link href="https://www.facebook.com/pflmma" target="_blank">
					<Icon
						src="https://clients2.titanium6.com/pfl-footer/img/facebook-f.svg"
						alt="PFL Facebook SVG"
					/>
				</Link>
				<Link href="https://twitter.com/PFLmma" target="_blank">
					<Icon
						src="https://clients2.titanium6.com/pfl-footer/img/twitter.svg"
						alt="PFL Twitter SVG"
					/>
				</Link>
				<Link href="https://www.tiktok.com/@pflmma?lang=en" target="_blank">
					<Icon
						src="https://clients2.titanium6.com/pfl-footer/img/tiktok.svg"
						alt="PFL TikTok SVG"
					/>
				</Link>
				<Link href="https://www.youtube.com/pflmma" target="_blank">
					<Icon
						src="https://clients2.titanium6.com/pfl-footer/img/youtube.svg"
						alt="PFL YouTube SVG"
					/>
				</Link>
				<Link href="https://www.instagram.com/pflmma/" target="_blank">
					<Icon
						src="https://clients2.titanium6.com/pfl-footer/img/instagram.svg"
						alt="PFL Instagram SVG"
					/>
				</Link>
			</Links>
		</Wrapper>
	);
};
