import React from "react";
import {IMAGES_URL} from "modules/constants";
import styled from "styled-components";

const FIGHTER_ITEM_HEIGHT = 45;

const Wrapper = styled.div`
	background: ${({theme}) => theme.background.fighterListItem};
	border-bottom: 1px solid ${({theme}) => theme.background.fighterListItemBorder};
	height: ${FIGHTER_ITEM_HEIGHT}px;
	display: flex;
	align-items: center;
	padding: 8px 10px;
	font-size: 14px;
`;

const Number = styled.div`
	color: ${({theme}) => theme.color.fighterListItemPosition};
	margin-right: 8px;
	font-weight: 900;
	width: 25px;
`;

const FighterLogoFigure = styled.div`
	margin-right: 18px;
	width: 30px;
`;

export const FighterLogo = styled.img`
	width: 100%;
	max-width: 100%;
`;

const Name = styled.div`
	font-weight: 700;
	color: ${({theme}) => theme.color.fighterListItem};
`;

interface IFighterItemProps {
	index: number;
	id: number;
	fullName: string;
	shortName: string;
}

export const FighterListItem: React.FC<IFighterItemProps> = ({index, shortName, fullName}) => {
	return (
		<Wrapper>
			<Number>#{index + 1}</Number>
			<FighterLogoFigure>
				<FighterLogo
					src={`${IMAGES_URL}fighters/headshots/${shortName}.png`}
					loading="lazy"
				/>
			</FighterLogoFigure>
			<Name>{fullName}</Name>
		</Wrapper>
	);
};
