import {HTTPClient} from "@fanhubmedia/fe-common-utils";
import {AxiosError} from "axios";
import {isObject} from "lodash";
import {API_URL, JSON_URL} from "modules/constants";
import {IApiResponse} from "modules/types";
import {ApiError} from "./ApiError";

const onCatchNetworkError = ({response, message = "Network error"}: AxiosError<ApiError>) => {
	const data = response?.data;
	const error = isObject(data)
		? data
		: {
				errors: [new ApiError(message)],
		  };

	return Promise.reject(error).catch((err) =>
		ApiError.CHECK(err as IApiResponse, response?.status)
	);
};

const APIClient = new HTTPClient({
	baseURL: API_URL,
	onCatchNetworkError,
});

const JSONClient = new HTTPClient({
	baseURL: JSON_URL,
	onCatchNetworkError,
});

export interface IAnswersPayload {
	user_key: string;
	answers: number[];
	firstName: string;
	lastName: string;
	email: string;
	country: string;
}

export const Api = {
	JSON: {
		fighters: <T>() => JSONClient.get<T>(`quiz/fighters.json`, {t: Date.now()}),
		questions: <T>() => JSONClient.get<T>(`quiz/questions.json`),
		lang: <T>() => JSONClient.get<T>(`quiz/loco/en.json`),
		countries: <T>() => JSONClient.get<T>(`quiz/countries.json`),
	},
	Country: {
		get: <T>() => APIClient.get<T>("country"),
	},
	Answers: {
		save: <T>(params: IAnswersPayload) => APIClient.post<T>("en/answers/save", params),
	},
};

export * from "./ApiError";
