import React, {useEffect} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {createConnextraScriptTag} from "modules/utils";
import {Exist, Footer, Menu, Questions, ScrollCTA, FightersList} from "components";

const Container = styled.div`
	position: relative;
`;

const Home: React.FC = observer(() => {
	const {I18n, UIQuestionsProgress, countries} = useStore();
	const {isAllQuestionsAnswered} = UIQuestionsProgress;

	useEffect(() => {
		void I18n.request();
		void countries.request();

		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_homepage"
		);
	}, [I18n, countries]);

	return (
		<div>
			<Container>
				<Menu />
				<FightersList />
				<Questions />
				<ScrollCTA />
			</Container>
			<Exist when={isAllQuestionsAnswered}>
				<Footer />
			</Exist>
		</div>
	);
});

export default Home;
