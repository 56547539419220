import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {QuestionType} from "modules/enums";
import {CTA, Exist, Registration, ResultScreen} from "components";
import {QuestionGeneral} from "./QuestionGeneral";
import {QuestionSlider} from "./QuestionSlider";
import {TrackQuestionViewingState} from "./common";

const {Slider, General} = QuestionType;

const questionMap = new Map([
	[Slider, QuestionSlider],
	[General, QuestionGeneral],
]);

export const Questions: React.FC = observer(() => {
	const {questions, UIQuestionsProgress} = useStore();
	const {
		isAllQuestionsAnswered,
		resultScreenID,
		CTAScreenID,
		registrationScreenID,
		isShowResultPage,
	} = UIQuestionsProgress;

	useEffect(() => {
		void questions.request();
	}, [questions]);

	const questionsList = UIQuestionsProgress.progressItems.map((question) => {
		const {type, id} = question;
		const Component = questionMap.get(type);

		return Component ? (
			<TrackQuestionViewingState key={id} id={question.id}>
				<Component {...question} />
			</TrackQuestionViewingState>
		) : null;
	});

	return (
		<React.Fragment>
			{questionsList}

			<Exist when={isAllQuestionsAnswered}>
				<TrackQuestionViewingState id={registrationScreenID}>
					<Registration />
				</TrackQuestionViewingState>

				<Exist when={isShowResultPage}>
					<TrackQuestionViewingState id={resultScreenID}>
						<ResultScreen />
					</TrackQuestionViewingState>

					<TrackQuestionViewingState id={CTAScreenID}>
						<CTA />
					</TrackQuestionViewingState>
				</Exist>
			</Exist>
		</React.Fragment>
	);
});
