export class Fighter {
	id: number = 0;
	fullName: string = "";
	shortName: string = "";
	priority: string = "";
	adMainTitle: string = "";
	adMainVideo: string = "";
	adMainImg: string = "";
	adMainImgMobile: string = "";
	adFirstTitle: string = "";
	adFirstImg: string = "";
	adFirstImgMobile: string = "";
	adFirstBtnTxt: string = "";
	adFirstBtnLink: string = "";
	adSecondTitle: string = "";
	adSecondImg: string = "";
	adSecondImgMobile: string = "";
	adSecondBtnTxt: string = "";
	adSecondBtnLink: string = "";

	constructor(args: Fighter) {
		Object.assign(this, args);
	}
}
