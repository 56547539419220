import React, {useCallback} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow.svg";

const Wrapper = styled.div`
	display: none;
	flex-direction: column;
	align-items: center;
	position: sticky;
	background-color: transparent;
	bottom: 4vh;
	z-index: 3;
	pointer-events: none;
	margin-bottom: 100px;

	&.isVisible {
		display: flex;
		opacity: 1;
	}

	&.reverse {
		pointer-events: auto;
		cursor: pointer;
	}

	@media (max-width: 840px) {
		bottom: 2vh;
		margin-bottom: 70px;
	}
`;

const Text = styled.div`
	text-transform: uppercase;
	font-size: 12px;
	font-weight: bold;
	letter-spacing: 3px;
`;

const Animate = styled.div<{isToTop: boolean}>`
	margin-bottom: ${({isToTop}) => (isToTop ? "25" : "5")}px;

	@keyframes bounceBottom {
		0% {
			opacity: 1;
			transform: translateY(0px) scale(1);
		}
		25% {
			opacity: 0;
			transform: translateY(10px) scale(0.9);
		}
		26% {
			opacity: 0;
			transform: translateY(-15px) scale(0.9);
		}
		55% {
			opacity: 1;
			transform: translateY(0px) scale(1);
		}
	}

	@keyframes bounceTop {
		0% {
			opacity: 1;
			transform: rotate(180deg) translateY(0px) scale(1);
		}
		25% {
			opacity: 0;
			transform: rotate(180deg) translateY(15px) scale(0.9);
		}
		26% {
			opacity: 0;
			transform: rotate(180deg) translateY(-10px) scale(0.9);
		}
		55% {
			opacity: 1;
			transform: rotate(180deg) translateY(0px) scale(1);
		}

		100% {
			transform: rotate(180deg);
		}
	}

	animation-name: ${({isToTop}) => (isToTop ? "bounceTop" : "bounceBottom")};
	animation-duration: 1.5s;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
`;

const getClassName = ({isScrollCTAVisible, isToTop}: Record<string, boolean>) => {
	let className = "";

	if (isScrollCTAVisible) {
		className += " isVisible";
	}

	if (isToTop) {
		className += " reverse";
	}

	return className;
};

export const ScrollCTA: React.FC = observer(() => {
	const {UIQuestionsProgress, I18n} = useStore();
	const isToTop = UIQuestionsProgress.isScrollCTAVisibleToTop;

	const className = getClassName({
		isScrollCTAVisible: UIQuestionsProgress.isScrollCTAVisible,
		isToTop,
	});

	const clickHandler = useCallback(() => {
		if (isToTop) {
			UIQuestionsProgress.resetProgress();
		}
	}, [isToTop, UIQuestionsProgress]);

	return (
		<Wrapper className={className} onClick={clickHandler}>
			<Animate isToTop={isToTop}>
				<ArrowIcon />
			</Animate>

			{isToTop && <Text>{I18n.t("scroll")}</Text>}
		</Wrapper>
	);
});
