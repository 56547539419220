export const getElementSize = (element: HTMLElement) => {
	const {paddingTop, paddingBottom, paddingLeft, paddingRight} = getComputedStyle(element);

	const elementHeight = element.offsetHeight; // height with padding
	const elementWidth = element.offsetWidth; // width with padding

	return {
		height: elementHeight - parseFloat(paddingTop) - parseFloat(paddingBottom),
		width: elementWidth - parseFloat(paddingLeft) - parseFloat(paddingRight),
	};
};

export * from "./createConnextraScriptTag";
export * from "./trackIdentify";
export * from "./dayWithNth";
export * from "./getLogo";
