import {makeObservable, computed, action, observable} from "mobx";
import {orderBy, keys, find, reduce, chain, transform} from "lodash";
import {RootStore} from "modules/stores/RootStore";

export class UIFighterList<TModel extends RootStore> {
	@observable isExpanded: boolean = false;

	constructor(private readonly rootStore: Pick<TModel, "fighters" | "UIQuestionsProgress">) {
		makeObservable(this);
	}

	@computed get renderVersion() {
		return this.items.map(({id}) => id).join("");
	}

	@computed get items() {
		const fightersWithWeight = this.rootStore.fighters.entities.map((fighter) => ({
			...fighter,
			weight: this.weights[fighter.id] || 0,
			percentage: this.percentages[fighter.id] || 0,
		}));

		return orderBy(fightersWithWeight, ["weight", "priority"], ["desc", "desc"]);
	}

	@computed get topFighter() {
		return this.items[0];
	}

	@computed get secondFighter() {
		return this.items[1];
	}

	@computed get thirdFighter() {
		return this.items[2];
	}

	@action toggle() {
		this.isExpanded = !this.isExpanded;
	}

	@computed get weights(): Record<number, number> {
		const answers = this.rootStore.UIQuestionsProgress.answers;
		const allItemsByID = this.rootStore.UIQuestionsProgress.allItemsByID;
		const answeredQuestionsIDs = keys(this.rootStore.UIQuestionsProgress.answers);

		const pointsPerAnswer = answeredQuestionsIDs.map((questionID) => {
			const selectedOptionID = answers[questionID];
			const question = allItemsByID[questionID];

			if (!question) {
				return {};
			}

			return find(question.options, {id: selectedOptionID})?.points;
		});

		return reduce(
			pointsPerAnswer,
			(acc: Record<string, number>, points?: Record<string, number>) => {
				if (!points) {
					return acc;
				}

				const fightersIDs = keys(points);

				fightersIDs.forEach((fighterID) => {
					const currentPoint = acc[fighterID] || 0;
					acc[fighterID] = currentPoint + points[fighterID];
				});

				return acc;
			},
			{}
		);
	}

	@computed get percentages(): Record<number, number> {
		const totalPoints = chain(this.weights)
			.values()
			.reduce((previousValue, currentValue) => previousValue + currentValue)
			.value();

		return transform(
			this.weights,
			(result, value, key) => (result[+key] = Math.round((value / totalPoints) * 100))
		);
	}
}
