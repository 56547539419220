import {QuestionType} from "modules/enums";

export class Question {
	public id: number = 0;
	public title: string = "";
	public shortTitle: string = "";
	public image: null | string = "";
	public mobileImage: null | string = "";
	public type: QuestionType = QuestionType.General;
	public options: Array<{
		id: number;
		title: string;
		image: string;
		mobileImage: string;
		points: Record<string, number>;
	}> = [];

	constructor(args: Question) {
		Object.assign(this, args);
	}
}

export interface IQuestion extends Question {
	hasAnswer: boolean;
	isActive: boolean;
	questionNumber: number;
}
