import React, {PropsWithChildren, useEffect} from "react";
import styled from "styled-components";
import {useVisible} from "react-hooks-visible";
import {useStore} from "modules/hooks";

export const QuestionWrapper = styled.div`
	width: 100%;
	min-height: 100vh;
`;

export const QuestionTitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 18px;
	margin-bottom: 20px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		align-items: center;
		flex-direction: row;
		margin-bottom: 40px;
		gap: 34px;
	}
`;

export const QuestionTitle = styled.h3`
	font-weight: 700;
	letter-spacing: 0.03em;
	font-size: 32px;
	color: ${({theme}) => theme.color.primary};
	text-align: left;
	text-transform: uppercase;
	text-shadow: 1px 1px 0 #000000;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 40px;
	}
`;

export const QuestionCounter = styled.div`
	font-weight: 700;
	line-height: 130%;
	color: ${({theme}) => theme.color.questionCounter};
	background: ${({theme}) => theme.background.questionCounter};
	font-size: 20px;
	padding: 14px 16px;
	letter-spacing: 1.5px;
`;

export const QuestionContainer = styled.div`
	min-height: 100%;
	position: relative;
	z-index: 2;
	padding: calc(8vh + 40px) 32px calc(4vh + 60px);

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: calc(8vh + 130px) 7% calc(4vh + 60px);
	}
`;

const Wrapper = styled.div``;

interface ITrackQuestionViewingStateProps {
	id: number;
}

export const TrackQuestionViewingState: React.FC<
	PropsWithChildren<ITrackQuestionViewingStateProps>
> = (props) => {
	const {children, id} = props;
	const {UIQuestionsProgress} = useStore();
	const [targetRef, visible] = useVisible<HTMLDivElement, boolean>((vi: number) => vi > 0.5);

	useEffect(() => {
		if (visible) {
			UIQuestionsProgress.setViewedQuestion(id);
		}
	}, [visible, id, UIQuestionsProgress]);

	return (
		<Wrapper id={`question-${id}`} ref={targetRef}>
			{children}
		</Wrapper>
	);
};
