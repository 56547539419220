import React, {ChangeEvent, SyntheticEvent, useCallback, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {MenuItem} from "@mui/material";
import {every, identity, isEmpty} from "lodash";
import {useStore} from "modules/hooks";
import {RequestState} from "modules/enums";
import {
	Checkbox,
	Exist,
	Form,
	FormField,
	FormTitle,
	Input,
	PrimaryButton,
	QuestionWrapper,
} from "components";
import {createConnextraScriptTag} from "modules/utils";

const Wrapper = styled(QuestionWrapper)`
	position: relative;
`;

const Container = styled.div`
	background-color: ${({theme}) => theme.background.quaternary};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: calc(8vh + 130px) 7% calc(4vh + 60px);

	@media (max-width: 840px) {
		padding: calc(8vh + 50px) 32px calc(4vh + 60px);
	}
`;

const Button = styled(PrimaryButton)`
	max-width: 480px;
	margin-top: 26px;
`;

const ErrorText = styled.div`
	width: 100%;
	color: ${({theme}) => theme.color.secondary};
	font-size: 16px;
	font-family: "Roboto", sans-serif;
`;

const CheckboxFormField = styled(FormField)`
	&.MuiFormControl-root {
		margin-left: 8px;
		margin-bottom: 0;
	}
`;

const FormLink = styled.div`
	font-size: 26px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 2px;
	color: ${({theme}) => theme.color.quaternary};

	a {
		font-weight: 700;
		text-decoration: underline;
	}
`;

const ActionsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const getIsValidData = (data: IData) => {
	return every(data, identity);
};

interface IData {
	firstName: string;
	lastName: string;
	email: string;
	country: string;
	terms: boolean;
}

export const Registration: React.FC = observer(() => {
	const {I18n, UIQuestionsProgress, countries} = useStore();
	const [data, setData] = useState<IData>({
		firstName: "",
		lastName: "",
		email: "",
		country: "",
		terms: false,
	});
	const errors = UIQuestionsProgress.errors;
	const isLoading = UIQuestionsProgress.apiState === RequestState.Requested;
	const isError = !isEmpty(errors);
	const isValid = getIsValidData(data);
	const isSubmittedForm = UIQuestionsProgress.isSubmittedForm;
	const isDisabled = isLoading || !isValid || isError || isSubmittedForm;

	const handleSubmit = useCallback(
		(e: SyntheticEvent<HTMLFormElement>) => {
			e.preventDefault();

			if (isDisabled) {
				return;
			}

			const {firstName, lastName, email, country, terms}: Record<string, HTMLInputElement> =
				e.currentTarget;

			const validateList = [{field: email, error: "registration.form.email", place: "email"}];

			const hasError = validateList.some(({field, error, place}) =>
				field.checkValidity() ? false : UIQuestionsProgress.reportError({[place]: error})
			);

			if (hasError) {
				return;
			}

			const payload = {
				firstName: firstName.value,
				lastName: lastName.value,
				email: email.value,
				country: country.value,
				terms: terms.checked,
			};

			void UIQuestionsProgress.saveAnswers(payload);
		},
		[UIQuestionsProgress, isDisabled]
	);

	const changeHandler = useCallback(
		(name: keyof typeof data) => (e: ChangeEvent<HTMLInputElement>) => {
			setData((prev) => ({
				...prev,
				[name]: e.target.value,
			}));

			UIQuestionsProgress.clearErrors();
		},
		[UIQuestionsProgress, setData]
	);

	const checkboxChangeHandler = useCallback(
		(name: keyof typeof data) => (e: ChangeEvent<HTMLInputElement>) => {
			setData((prev) => ({
				...prev,
				[name]: e.target.checked,
			}));

			UIQuestionsProgress.clearErrors();
			createConnextraScriptTag(
				"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_optin"
			);
		},
		[UIQuestionsProgress, setData]
	);

	return (
		<Wrapper>
			<Container>
				<Form onSubmit={handleSubmit} noValidate>
					<FormTitle>{I18n.t("registration.form.title")}</FormTitle>

					<div>
						<FormField>
							<Input
								required
								variant="standard"
								error={!!errors.firstName}
								label={I18n.t("registration.form.first_name_label")}
								placeholder={I18n.t("registration.form.first_name_placeholder")}
								name="firstName"
								helperText={I18n.t(errors.firstName)}
								value={data.firstName}
								onChange={changeHandler("firstName")}
							/>
						</FormField>

						<FormField>
							<Input
								required
								variant="standard"
								error={!!errors.lastName}
								label={I18n.t("registration.form.last_name_label")}
								placeholder={I18n.t("registration.form.last_name_placeholder")}
								name="lastName"
								helperText={I18n.t(errors.lastName)}
								value={data.lastName}
								onChange={changeHandler("lastName")}
							/>
						</FormField>

						<FormField>
							<Input
								required
								variant="standard"
								error={!!errors.email}
								label={I18n.t("registration.form.email_label")}
								placeholder={I18n.t("registration.form.email_placeholder")}
								name="email"
								type="email"
								helperText={I18n.t(errors.email)}
								value={data.email}
								onChange={changeHandler("email")}
							/>
						</FormField>

						<FormField>
							<Input
								select
								required
								variant="standard"
								error={!!errors.country}
								label={I18n.t("registration.form.country_label")}
								placeholder={I18n.t("registration.form.country_placeholder")}
								name="country"
								helperText={I18n.t(errors.country)}
								value={data.country}
								onChange={changeHandler("country")}
								SelectProps={{
									MenuProps: {
										PaperProps: {sx: {maxHeight: 400}},
									},
								}}>
								{countries.data.map(({code, name}) => (
									<MenuItem key={code} value={code}>
										{name}
									</MenuItem>
								))}
							</Input>
						</FormField>

						<CheckboxFormField>
							<Checkbox
								name="terms"
								html={I18n.t("registration.form.terms")}
								value={data.terms}
								onChange={checkboxChangeHandler("terms")}
							/>
						</CheckboxFormField>

						<Exist when={!!errors[""]}>
							<ErrorText>{I18n.t(errors[""])}</ErrorText>
						</Exist>
					</div>

					<ActionsContainer>
						<FormLink>
							{I18n.t("registration.form.follow")}
							&nbsp;
							<a href="https://pflmma.com/">https://pflmma.com/</a>
						</FormLink>

						<Button disabled={isDisabled}>
							{isSubmittedForm
								? I18n.t("registration.form.submitted")
								: I18n.t("registration.form.submit")}
						</Button>
					</ActionsContainer>
				</Form>
			</Container>
		</Wrapper>
	);
});
