import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Outlet} from "react-router-dom";
import styled from "styled-components";
import {IS_SECRET, LOGIN_DISABLED} from "modules/constants";
import {PersistStorage} from "modules/utils";

const SecretPage = styled.div`
	background-color: #000000;
	width: 100vw;
	height: 100vh;
`;

export const Layout: React.FC = () => {
	const [count, setCount] = useState(0);
	const isSecret = count < 10;

	useEffect(() => {
		if (!isSecret) {
			PersistStorage.SET(LOGIN_DISABLED, "false");
		}
	}, [isSecret]);

	const clickHandler = useCallback(() => {
		setCount((count) => ++count);
	}, [setCount]);

	if (IS_SECRET && isSecret) {
		return <SecretPage onClick={clickHandler} />;
	}

	return (
		<Fragment>
			<Outlet />
		</Fragment>
	);
};
