import {makeObservable, observable} from "mobx";

export class Modal {
	@observable public error: string = "";

	constructor() {
		makeObservable(this);
	}

	setError(err: string) {
		this.error = err;
	}

	clearError() {
		this.error = "";
	}
}
