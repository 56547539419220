import {PersistStorage} from "modules/utils";

export const ENV = process.env.REACT_APP_ENVIRONMENT;
export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const FB_SHARE_URL = process.env.REACT_APP_FB_SHARE_URL || "";

export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || "";
export const LOGIN_DISABLED = "__LOGIN_DISABLED__";
export const IS_SECRET = JSON.parse(
	PersistStorage.GET(LOGIN_DISABLED) || process.env.REACT_APP_IS_SECRET || "false"
) as boolean;
export const LINK = process.env.REACT_APP_LINK || "";
