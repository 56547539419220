import React, {Fragment, useCallback, useEffect} from "react";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {CloseButton, ModalIcon, PrimaryButton} from "components";
import {
	ButtonsBox,
	Modal,
	ModalContent,
	ModalInner,
	ModalText,
	ModalTitle,
	Overlay,
} from "components/Modal";
import icon from "assets/images/icons/icon-warning.svg";
import {ReactComponent as CloseIcon} from "assets/images/icons/close.svg";

export const ErrorModal: React.FC = observer(() => {
	const {modal, I18n} = useStore();
	const errorMessage = modal.error;

	const handleClose = useCallback(() => {
		modal.clearError();
	}, [modal]);

	useEffect(() => {
		const img = new Image(); // load image before offline mode
		img.src = icon;
	}, []);

	if (!errorMessage) {
		return null;
	}

	return (
		<Fragment>
			<Overlay onClick={handleClose} />
			<Modal>
				<ModalInner>
					<ModalContent>
						<CloseButton onClick={handleClose}>
							<CloseIcon />
						</CloseButton>
						<ModalIcon src={icon} alt="Error" />
						<ModalTitle>{I18n.t("modal.error.title")}</ModalTitle>
						<ModalText>{I18n.t(errorMessage)}</ModalText>
						<ButtonsBox>
							<PrimaryButton onClick={handleClose}>
								{I18n.t("modal.error.button")}
							</PrimaryButton>
						</ButtonsBox>
					</ModalContent>
				</ModalInner>
			</Modal>
		</Fragment>
	);
});
