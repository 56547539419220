import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {size, toInteger} from "lodash";
import styled from "styled-components";
import {IQuestion} from "modules/stores/Models";
import {useHasScroll, useStore} from "modules/hooks";
import {startDrag, startSlide, updateDragIconPosition} from "modules/utils";
import {IMAGES_URL} from "modules/constants";
import {PureButton} from "components";
import {
	QuestionContainer,
	QuestionCounter,
	QuestionTitle,
	QuestionTitleContainer,
	QuestionWrapper,
} from "./common";
import dragIcon from "assets/images/icons/drag.svg";
import logo from "assets/images/logo.svg";

const Wrapper = styled(QuestionWrapper)`
	position: relative;
	text-align: center;
`;

const Container = styled(QuestionContainer)`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-left: 0;
	padding-right: 0;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: calc(8vh + 30px) 7% calc(4vh + 60px);
	}
`;

const QuestionContentContainer = styled.div`
	width: 100%;
	overflow: hidden;
	box-sizing: border-box;
	padding: 0 35px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: 0 5%;
	}
`;

const OptionsList = styled.div`
	position: relative;
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: 1;
`;

const ImagesList = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
	will-change: contents;
`;

interface IBGImageProps {
	background: string;
	backgroundMobile?: string;
}

const getWrapperBackground = ({background}: IBGImageProps) =>
	background
		? `url(${IMAGES_URL}image/${background}) no-repeat center center / cover #1b0741`
		: `#ffffff`;

const BGImage = styled.div<IBGImageProps>`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	transition: all 1.5s;
	z-index: 1;
	opacity: 0;

	&.active {
		opacity: 1;
	}

	background: ${({background, backgroundMobile}) =>
		getWrapperBackground({background: backgroundMobile || background})};

	${({theme}) => theme.breakpoints.up("mobile")} {
		background: ${getWrapperBackground};
	}
`;

const Option = styled(PureButton)`
	font-size: 20px;
	line-height: 90%;
	color: #ffffff;
	text-align: center;
	transition: color 0.5s;
	text-transform: uppercase;
	max-width: 85px;
	text-shadow: 2px 2px 0 #000000;

	&:hover {
		background-size: 50% 1px;
	}

	&.active {
		color: #ffffff;
		text-shadow: 2px 2px 0 ${({theme}) => theme.color.tertiary};
	}

	${({theme}) => theme.breakpoints.up("small_mobile")} {
		font-size: 28px;
		max-width: 150px;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		max-width: 150px;
		letter-spacing: 2px;
		font-size: 32px;
	}

	${({theme}) => theme.breakpoints.up("tablet")} {
		max-width: none;
		font-size: 40px;
	}
`;

interface ISliderProps {
	hasScroll: boolean;
}

const Slider = styled.div<ISliderProps>`
	position: relative;
	width: 100%;
	overflow: hidden;
	padding: 15px;
	margin-top: 40px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: 30px;
		max-width: 1440px;
	}
`;

const SliderLine = styled.hr`
	background: linear-gradient(
		90deg,
		rgba(255, 255, 255, 0) -23.86%,
		#4d4d4d 50.52%,
		rgba(255, 255, 255, 0) 119.07%
	);
	height: 3px;
	border: 0;
	display: block;
	margin: 0 0 38px 0;

	${({theme}) => theme.breakpoints.up("mobile")} {
		height: 6px;
		margin: 0 0 52px 0;
	}
`;

const ButtonDrag = styled(PureButton)`
	background: url(${dragIcon}) #013369 no-repeat center center;
	cursor: grab;
	display: block;
	width: 32px;
	height: 32px;
	background-size: 20px;
	border-radius: 100%;
	transform: translateX(-50%);
	position: absolute;
	left: 31px;
	top: 0;
	transition: all 0.5s;
	z-index: 2;

	& > svg {
		pointer-events: none;
	}

	&:hover {
		background-color: #002247;
	}

	&.in-drag {
		transition: none;
		cursor: grabbing;
	}

	${({theme}) => theme.breakpoints.up("small_mobile")} {
		width: 42px;
		height: 42px;
		background-size: 30px;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 62px;
		height: 62px;
		background-size: 39px;
	}
`;

const SliderContent = styled.div`
	display: inline-block;
	min-width: 100%;
	transform: translateX(0);
	transition: all 0.5s;
`;

const Title = styled.h1`
	font-weight: 700;
	font-size: 44px;
	line-height: 120%;
	color: ${({theme}) => theme.color.secondary};
	margin-bottom: 6px;
	letter-spacing: 0.03em;
	text-shadow: 1px 1px 0 #000000;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 60px;
	}
`;

const SubTitle = styled.h2`
	font-style: italic;
	font-weight: 700;
	font-size: 32px;
	line-height: 130%;
	color: ${({theme}) => theme.color.primary};
	letter-spacing: 2px;
	text-shadow: 1px 1px 0 #000000;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 55px;
		letter-spacing: normal;
	}
`;

const Logo = styled.img`
	width: 140px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 201px;
	}
`;

const QuestionTitleContainerStyled = styled(QuestionTitleContainer)`
	margin-top: 60px;
	justify-content: center;

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-top: 8px;
	}
`;

const AUTO_SCROLL_INTERVAL_SEC = 3.5;

export const QuestionSlider: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	questionNumber,
}) => {
	const {I18n, UIQuestionsProgress} = useStore();
	const noOfQuestions = UIQuestionsProgress.noOfQuestions;
	const dragRef = useRef<HTMLButtonElement>(null);
	const sliderRef = useRef<HTMLDivElement>(null);
	const hasScroll = useHasScroll(sliderRef);

	const [activeIndex, setActiveIndex] = useState<number | null>(null);
	const [autoTransitionActiveIndex, setAutoTransitionActiveIndex] = useState(0);
	const autoTransition = activeIndex === null;
	const imagesSize = size(options);

	const getActiveOptionClass = useCallback(
		(index: number) => (index === activeIndex ? "active" : ""),
		[activeIndex]
	);

	const getActiveImageClass = useCallback(
		(index: number) => {
			if (autoTransition) {
				return index === autoTransitionActiveIndex ? "active" : "";
			}

			return index === activeIndex ? "active" : "";
		},
		[activeIndex, autoTransitionActiveIndex, autoTransition]
	);

	useEffect(() => {
		if (!autoTransition || !imagesSize) {
			return;
		}

		const timeoutID = setTimeout(() => {
			const newIndex = (autoTransitionActiveIndex + 1) % imagesSize;
			setAutoTransitionActiveIndex(newIndex);
		}, AUTO_SCROLL_INTERVAL_SEC * 1000);

		return () => {
			clearTimeout(timeoutID);
		};
	}, [autoTransition, imagesSize, autoTransitionActiveIndex]);

	const moveSlide = useMemo(() => {
		const callback = hasScroll ? startDrag : startSlide;

		return callback(setActiveIndex, UIQuestionsProgress.selectAnswer.bind(UIQuestionsProgress));
	}, [UIQuestionsProgress, hasScroll]);

	const onSelectOption = useCallback(
		(event: React.SyntheticEvent<HTMLButtonElement>) => {
			if (hasScroll) {
				return;
			}

			const element = event.currentTarget;
			const {index, questionId, optionId} = element.dataset;

			setActiveIndex(toInteger(index));
			UIQuestionsProgress.selectAnswer(toInteger(questionId), toInteger(optionId));

			updateDragIconPosition(element, dragRef.current);
		},
		[UIQuestionsProgress, dragRef, hasScroll]
	);

	return (
		<Wrapper>
			<ImagesList>
				{options.map(({image, title, id, mobileImage}, index) => (
					<BGImage
						className={getActiveImageClass(index)}
						background={image}
						backgroundMobile={mobileImage}
						key={id}
					/>
				))}
			</ImagesList>
			<Container>
				<QuestionContentContainer>
					<Logo src={logo} alt="PFL" />
					<Title>{I18n.t("global.header.title")}</Title>
					<SubTitle>{I18n.t("global.header.sub_title")}</SubTitle>

					<QuestionTitleContainerStyled>
						<QuestionCounter>
							Q{questionNumber}/{noOfQuestions}
						</QuestionCounter>
						<QuestionTitle>{title}</QuestionTitle>
					</QuestionTitleContainerStyled>
				</QuestionContentContainer>
				<Slider hasScroll={hasScroll} ref={sliderRef}>
					<ButtonDrag ref={dragRef} onMouseDown={moveSlide} onTouchStart={moveSlide} />
					<SliderContent>
						<SliderLine />
						<OptionsList>
							{options.map(({id, title, image}, index) => (
								<Option
									key={id}
									className={getActiveOptionClass(index)}
									data-question-id={questionID}
									data-option-id={id}
									data-index={index}
									onClick={onSelectOption}>
									{title}
								</Option>
							))}
						</OptionsList>
					</SliderContent>
				</Slider>
			</Container>
		</Wrapper>
	);
};
