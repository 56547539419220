import {Api} from "modules/utils/Api";
import {Fingerprint, I18n, Modal, Question, Fighter, Countries} from "modules/stores/Models";
import {JSONCollection} from "modules/stores/Collections";
import {UIQuestionsProgress, UIFighterList} from "modules/stores/UIStores";

export class RootStore {
	fingerprint: Fingerprint;
	fighters: JSONCollection<Fighter>;
	questions: JSONCollection<Question>;
	UIFighterList: UIFighterList<RootStore>;
	UIQuestionsProgress: UIQuestionsProgress<RootStore>;
	I18n: I18n;
	modal: Modal;
	countries: Countries;

	constructor() {
		this.fingerprint = new Fingerprint();
		this.fighters = new JSONCollection(Fighter, Api.JSON.fighters);
		this.questions = new JSONCollection(Question, Api.JSON.questions);
		this.UIFighterList = new UIFighterList(this);
		this.UIQuestionsProgress = new UIQuestionsProgress(this);
		this.I18n = new I18n();
		this.modal = new Modal();
		this.countries = new Countries();
	}
}

export default RootStore;
