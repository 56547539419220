import React, {useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";
import {Flipped, Flipper} from "react-flip-toolkit";
import {useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {Exist, PureButton} from "components";
import {IconArrow} from "components/Arrow";
import {FighterListItem, FighterLogo} from "./FighterListItem";

const Wrapper = styled.div`
	font-family: "Hind", sans-serif;
	position: fixed;
	right: 50px;
	top: 8vh;
	max-height: 84vh;
	width: 320px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	border-radius: 2px;
	z-index: 10;
`;

const Header = styled.div`
	background: ${({theme}) => theme.background.tertiary};
	padding: 16px 10px;
	font-size: 16px;
	font-weight: 400;
	line-height: 130%;
	font-family: "Normalidad", sans-serif;
`;

const FIGHTER_ITEM_HEIGHT = 45;

interface IListProps {
	isExpanded: boolean;
}

const List = styled.div<IListProps>`
	background: #fff;
	color: #000;
	max-height: ${(props) => (props.isExpanded ? "100vh" : `${FIGHTER_ITEM_HEIGHT * 3}px`)};
	transition: all 0.5s linear;
	overflow: auto;

	/* Hide scrollbar for Chrome, Safari and Opera */
	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
`;

const Button = styled(PureButton)`
	padding: 10px 0;
	font-weight: 700;
	color: ${({theme}) => theme.color.fighterListButton};
	background: ${({theme}) => theme.background.fighterListButton};
	width: 100%;
	text-transform: uppercase;
	font-size: 20px;
	line-height: 130%;
	text-align: center;
	letter-spacing: 1.5px;
`;

const MobileButton = styled(PureButton)`
	background: #fff;
	position: fixed;
	right: 35px;
	top: 48px;
	width: 48px;
	height: 48px;
	border-radius: 100%;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-25%);
	border: 1px solid #cccccc;

	img {
		max-width: 30px;
		height: auto;
	}

	${IconArrow} {
		z-index: 2;
		position: absolute;
		bottom: -24px;
	}
`;

const CloseButton = styled(MobileButton)`
	background: #000;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

	&:before,
	&:after {
		background: #fff;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 21px;
		height: 3px;
	}

	&:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
`;

const Overlay = styled.div`
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 15;
	display: flex;
	justify-content: flex-end;
`;

const MobileListContainer = styled.div`
	min-width: 320px;
	display: flex;
	flex-flow: column;
`;

const QuizStart = styled.div`
	background-color: #fff;
	color: #292929;
	padding: 16px 17px;
	font-size: 14px;
	line-height: 22px;
`;

const getIsVisible = ({
	isMobileView,
	isQuizStarted,
	isCTAScreenVisible,
	isResultScreenVisible,
	isRegistrationScreenVisible,
}: Record<string, boolean>) => {
	if (isRegistrationScreenVisible) {
		return false;
	}

	if (isCTAScreenVisible) {
		return false;
	}

	if (isResultScreenVisible) {
		return false;
	}

	return isMobileView && isQuizStarted;
};

const getIsVisibleDesktop = ({
	isRegistrationScreenVisible,
	isMobileView,
	isCTAScreenVisible,
	isResultScreenVisible,
}: Record<string, boolean>) => {
	if (isRegistrationScreenVisible) {
		return false;
	}

	if (isCTAScreenVisible) {
		return false;
	}

	if (isResultScreenVisible) {
		return false;
	}

	return !isMobileView;
};

export const FightersList: React.FC = observer(() => {
	const listRef = useRef<HTMLDivElement>(null);
	const {fighters, UIQuestionsProgress, UIFighterList, I18n} = useStore();
	const isQuizStarted = UIQuestionsProgress.isAnyQuestionAnswered;
	const {isExpanded} = UIFighterList;
	const [isMobileListVisible, setMobileListVisibleState] = useState(false);
	const isMobileView = useMediaQuery("(max-width: 1000px)");
	const {resultScreenID, CTAScreenID, viewedQuestionID, registrationScreenID} =
		UIQuestionsProgress;
	const isResultScreenVisible = resultScreenID === viewedQuestionID;
	const isCTAScreenVisible = CTAScreenID === viewedQuestionID;
	const isRegistrationScreenVisible = registrationScreenID === viewedQuestionID;
	const isVisible = getIsVisible({
		isMobileView,
		isQuizStarted,
		isResultScreenVisible,
		isCTAScreenVisible,
		isRegistrationScreenVisible,
	});
	const isVisibleDesktop = getIsVisibleDesktop({
		isMobileView,
		isResultScreenVisible,
		isCTAScreenVisible,
		isRegistrationScreenVisible,
	});

	useEffect(() => {
		void fighters.request();
	}, [fighters]);

	useEffect(() => {
		if (!isExpanded && listRef.current) {
			listRef.current.scroll({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [isExpanded, listRef]);

	const btnText = I18n.t(isExpanded ? "fighters_list.btn.less" : "fighters_list.btn.more");

	if (isVisible) {
		return (
			<React.Fragment>
				<MobileButton onClick={() => setMobileListVisibleState(true)}>
					<FighterLogo
						src={`${IMAGES_URL}fighters/headshots/${UIFighterList.topFighter?.shortName}.png`}
					/>
					<IconArrow />
				</MobileButton>
				<Exist when={isMobileListVisible}>
					<Overlay>
						<CloseButton onClick={() => setMobileListVisibleState(false)} />
						<MobileListContainer>
							<Header>{I18n.t("fighters_list.header.title")}</Header>
							<List isExpanded>
								{UIFighterList.items.map(({id, shortName, fullName}, index) => (
									<FighterListItem
										key={id}
										id={id}
										index={index}
										shortName={shortName}
										fullName={fullName}
									/>
								))}
							</List>
						</MobileListContainer>
					</Overlay>
				</Exist>
			</React.Fragment>
		);
	}

	if (isVisibleDesktop) {
		return (
			<Wrapper>
				<Header>{I18n.t("fighters_list.header.title")}</Header>
				<Exist when={isQuizStarted}>
					<List ref={listRef} isExpanded={isExpanded}>
						<Flipper flipKey={UIFighterList.renderVersion}>
							{UIFighterList.items.map(({id, shortName, fullName}, index) => (
								<Flipped key={id} flipId={id}>
									<div>
										<FighterListItem
											id={id}
											index={index}
											shortName={shortName}
											fullName={fullName}
										/>
									</div>
								</Flipped>
							))}
						</Flipper>
					</List>
					<Button onClick={() => UIFighterList.toggle()}>{btnText}</Button>
				</Exist>
				<Exist when={!isQuizStarted}>
					<QuizStart>{I18n.t("fighters_list.content.quiz_start")}</QuizStart>
				</Exist>
			</Wrapper>
		);
	}

	return null;
});
