import React, {HTMLAttributes} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {useStore} from "modules/hooks";
import {PureButton} from "components/Buttons";

const MenuButton = styled(PureButton)`
	left: 35px;
	width: 30px;
	height: 30px;
	position: fixed;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 12;
	top: 45px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 40px;
		height: 40px;
		left: 5.6vw;
	}

	&.blue span {
		background: ${({theme}) => theme.background.secondary};
	}

	span {
		display: block;
		position: absolute;
		height: 3px;
		width: 50%;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 0.25s ease-in-out;
		background: ${({theme}) => theme.background.quaternary};

		&:nth-child(even) {
			left: 50%;
		}

		&:nth-child(odd) {
			left: 0;
		}

		&:nth-child(1),
		&:nth-child(2) {
			top: 0;
		}

		&:nth-child(3),
		&:nth-child(4) {
			top: 8px;
		}

		&:nth-child(5),
		&:nth-child(6) {
			top: 16px;
		}
	}

	&.open span {
		background: ${({theme}) => theme.background.secondary};

		&:nth-child(1),
		&:nth-child(6) {
			transform: rotate(45deg);
		}

		&:nth-child(2),
		&:nth-child(5) {
			transform: rotate(-45deg);
		}

		&:nth-child(1) {
			left: 2px;
			top: 4px;
		}

		&:nth-child(2) {
			left: calc(50% - 2px);
			top: 4px;
		}

		&:nth-child(3) {
			left: -50%;
			opacity: 0;
		}

		&:nth-child(4) {
			left: 100%;
			opacity: 0;
		}

		&:nth-child(5) {
			left: 2px;
			top: 12px;
		}

		&:nth-child(6) {
			left: calc(50% - 2px);
			top: 12px;
		}
	}
`;

const ButtonInner = styled.div`
	width: 26px;
	height: 19px;
	position: relative;
	transition: 0.5s ease-in-out;
	transform: rotate(0deg);
`;

const getClassName = (elementsClassName: string, defaultClassName = "") =>
	`${elementsClassName} ${defaultClassName}`;

export const HamburgerButton: React.FC<HTMLAttributes<HTMLButtonElement>> = observer((props) => {
	const {UIQuestionsProgress} = useStore();
	const className = getClassName(UIQuestionsProgress.elementsClassName, props.className);

	return (
		<MenuButton {...props} className={className}>
			<ButtonInner>
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
			</ButtonInner>
		</MenuButton>
	);
});
