import {RefObject, useEffect, useState} from "react";

export interface ISliderData {
	is_show_nav: boolean;
	is_disabled_left: boolean;
	is_disabled_right: boolean;
}

export const useSlider = (ref: RefObject<HTMLDivElement>, is_mobile = false) => {
	const [element, setElement] = useState<HTMLDivElement | null>(ref.current);
	const [state, setState] = useState<ISliderData>({
		is_show_nav: false,
		is_disabled_left: false,
		is_disabled_right: false,
	});
	const scroll_size = is_mobile ? 260 : 270;

	useEffect(() => {
		if (ref.current) {
			setState({
				is_show_nav: ref.current.scrollWidth > ref.current.clientWidth,
				is_disabled_left: ref.current.scrollLeft === 0,
				is_disabled_right:
					ref.current.scrollLeft + ref.current.clientWidth >= ref.current.scrollWidth,
			});
		}

		setElement(ref.current);
	}, [ref]);

	if (!element) {
		return;
	}

	const scrollSlider = (right = false) => {
		const scrolledLeft = right
			? element.scrollLeft + scroll_size
			: element.scrollLeft - scroll_size;

		element.scroll({
			left: scrolledLeft,
			behavior: "smooth",
		});

		setState({
			...state,
			is_disabled_left: scrolledLeft <= 0,
			is_disabled_right: scrolledLeft + element.clientWidth >= element.scrollWidth,
		});
	};

	return {
		...state,
		scrollRight: () => scrollSlider(true),
		scrollLeft: () => scrollSlider(),
	};
};
