import React, {useCallback} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {get} from "lodash";
import Confetti from "react-confetti";
import {useIsMobile, useStore, useWindowSize} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {Exist, PureButton, QuestionContainer, QuestionWrapper} from "components";
import {ReactComponent as ArrowIcon} from "assets/images/icons/arrow.svg";
import {createConnextraScriptTag, share} from "modules/utils";
import {ShareType} from "modules/enums";
import {ReactComponent as FacebookIcon} from "assets/images/icons/facebook.svg";
import {ReactComponent as TwitterIcon} from "assets/images/icons/twitter.svg";
import {ReactComponent as ShareIcon} from "assets/images/icons/share.svg";

const Wrapper = styled(QuestionWrapper)`
	display: flex;
	flex-direction: column;
	align-items: center;
	background: #fbfbfb;
	padding: 20px 0 0;
	position: relative;

	${({theme}) => theme.breakpoints.up("mobile")} {
		background: linear-gradient(
			1.88deg,
			rgba(255, 255, 255, 0) -11.25%,
			#c7c7c7 31.32%,
			#e5e5e5 84.58%,
			rgba(229, 229, 229, 0) 105.92%
		);
	}
`;

const Container = styled(QuestionContainer)`
	width: 100%;
	padding-left: 0;
	padding-right: 0;
	flex-grow: 1;
	padding-top: 8vh;

	${({theme}) => theme.breakpoints.up("mobile")} {
		max-width: 1440px;
		padding-left: calc(250px + 7%);
	}
`;

const FighterContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`;

const ActionsContainer = styled(PureButton)`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background: #fbfbfb;
	color: ${({theme}) => theme.color.tertiary};
	padding: 0 20px 30px;
	flex-grow: 1;
	font-weight: 700;
	font-size: 32px;
	line-height: 34px;
	text-align: center;
	letter-spacing: 0.03em;

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-top: 150px;
		background: transparent;
	}
`;

const FighterImageContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
	order: 3;

	&:after {
		content: "";
		position: absolute;
		width: 100%;
		height: 30px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fbfbfb 100%);
		left: 0;
		bottom: 0;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		position: static;

		&:after {
			content: none;
		}
	}
`;

const FighterImage = styled.img`
	height: 473px;
	max-height: 60vh;
	width: auto;
	max-width: 200px;
	object-fit: cover;
	margin-top: 20px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-top: 0;
		z-index: -1;
		position: absolute;
		left: 4%;
		max-width: 400px;
		bottom: 0;
		width: 70%;
		height: 100%;
		max-height: 100vh;
		order: 5;
		object-fit: contain;
	}

	${({theme}) => theme.breakpoints.up("tablet")} {
		left: 10%;
	}

	${({theme}) => theme.breakpoints.up("desktop")} {
		left: 15%;
	}
`;

const Title = styled.h2`
	color: ${({theme}) => theme.color.tertiary};
	font-weight: 700;
	font-size: 44px;
	text-align: center;
	letter-spacing: 0.03em;
	text-transform: uppercase;
	margin-bottom: 16px;
	order: 2;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 60px;
		margin-bottom: 24px;
	}
`;

const Text = styled.div`
	order: 4;

	& * {
		color: ${({theme}) => theme.color.quaternary};
		font-size: 20px;
		line-height: 130%;
		text-align: center;
		letter-spacing: 2px;
	}

	.underline {
		text-decoration: underline;
	}
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
`;

const TextWithButton = styled(Text)`
	display: flex;
	align-items: center;

	svg path {
		fill: #4d4d4d;
	}
`;

const SubTitle = styled.h3`
	font-weight: 700;
	font-size: 32px;
	line-height: 34px;
	color: ${({theme}) => theme.color.tertiary};
	text-align: center;
	letter-spacing: 2px;
	margin-bottom: 20px;
	order: 1;

	${({theme}) => theme.breakpoints.up("mobile")} {
		margin-bottom: 26px;
	}
`;

export const ResultScreen: React.FC = observer(() => {
	const {height, width} = useWindowSize();
	const {UIFighterList, I18n, UIQuestionsProgress} = useStore();
	const topFighter = UIFighterList.topFighter;
	const logo = `${IMAGES_URL}fighters/${topFighter?.shortName}.png`;
	const name = get(topFighter, "fullName", "");
	const {isMobile} = useIsMobile();

	const clickHandler = useCallback(() => {
		UIQuestionsProgress.scrollToNextScreen(UIQuestionsProgress.CTAScreenID);
	}, [UIQuestionsProgress]);

	const onShare = useCallback((e: React.SyntheticEvent<HTMLButtonElement>) => {
		share({
			sc: Number(e.currentTarget.value) as unknown as ShareType,
		});
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_shareclick"
		);
	}, []);

	const restartQuizHandler = useCallback(() => {
		UIQuestionsProgress.resetProgress();
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_tryagainclick"
		);
	}, [UIQuestionsProgress]);

	return (
		<Wrapper>
			<Container>
				<FighterContainer>
					<SubTitle>{I18n.t("screens.result.subTitle")}</SubTitle>
					<Title>{name}</Title>
					<TextWithButton>
						<span>{I18n.t("screens.result.share")}</span>
						&nbsp;
						<Exist when={!isMobile}>
							<Buttons>
								<PureButton type="submit" onClick={onShare} value={ShareType.FB}>
									<FacebookIcon />
								</PureButton>
								<PureButton type="submit" onClick={onShare} value={ShareType.TW}>
									<TwitterIcon />
								</PureButton>
							</Buttons>
						</Exist>
						<Exist when={isMobile}>
							<PureButton type="submit" onClick={onShare} value={ShareType.MOBILE}>
								<ShareIcon />
							</PureButton>
						</Exist>
					</TextWithButton>

					<Text>
						<PureButton onClick={restartQuizHandler}>
							{I18n.t("screens.result.try_again")}
						</PureButton>
					</Text>

					<FighterImageContainer>
						<FighterImage src={logo} alt={name} />
					</FighterImageContainer>
				</FighterContainer>

				<ActionsContainer onClick={clickHandler}>
					<div>{I18n.t("screens.result.get_know_team")}</div>
					<ArrowIcon />
				</ActionsContainer>
			</Container>

			<Confetti
				width={width}
				height={height}
				colors={["#FF0404", "#052383", "#4D4D4D"]}
				numberOfPieces={400}
				opacity={0.9}
			/>
		</Wrapper>
	);
});
