import React, {useRef, useState} from "react";
import styled from "styled-components";
import {useMediaQuery} from "@mui/material";
import {IQuestion} from "modules/stores/Models";
import {
	QuestionContainer,
	QuestionCounter,
	QuestionTitle,
	QuestionTitleContainer,
	QuestionWrapper,
} from "./common";
import {Exist} from "components/Exist";
import {useSlider, useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {IconArrow} from "components/Arrow";

interface IWrapperProps {
	background: null | string;
	backgroundMobile?: null | string;
}

const getWrapperBackground = ({background}: IWrapperProps) =>
	background
		? `url(${IMAGES_URL}image/${background}) no-repeat center center / cover #1b0741`
		: `#ffffff`;

const Wrapper = styled(QuestionWrapper)<IWrapperProps>`
	background: ${({background, backgroundMobile}) =>
		getWrapperBackground({background: backgroundMobile || background})};

	${({theme}) => theme.breakpoints.up("mobile")} {
		background: ${getWrapperBackground};
	}
`;

const OptionsList = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	overflow: hidden;
	scroll-snap-type: x mandatory;

	@media (max-width: 768px) {
		flex-wrap: wrap;
		gap: 20px;
	}
`;

const ImageContainer = styled.div`
	position: relative;
	padding-top: 100%;
`;

const Image = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const FigureCaption = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Normalidad", sans-serif;
	font-size: 16px;
	font-weight: 500;
	line-height: 130%;
	padding: 12px 10px 10px;
	min-height: 44px;
	flex-grow: 1;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-weight: 700;
		font-size: 18px;
		min-height: 48px;
	}
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-right: 20px;
	cursor: pointer;
	transition: all 0.5s;
	width: 100%;
	color: ${({theme}) => theme.color.questionGeneral};
	background: ${({theme}) => theme.background.questionGeneral};
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
	padding: 2px;
	margin-bottom: 4px;
	max-width: 260px;
	min-width: 260px;

	@media (max-width: 768px) {
		max-width: none;
		margin-right: 0;
		min-width: 120px;
		width: calc(50% - 12px);
	}

	&:last-child {
		margin-right: 0;
	}

	&.active {
		background: ${({theme}) => theme.background.secondary};
		color: ${({theme}) => theme.color.primary};

		${FigureCaption} {
			background-size: 100% 1px;
		}
	}

	&.fade {
		opacity: 0.5;
	}
`;

const WrapperScrollNaw = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
	margin-top: 10px;
`;

const ScrollButton = styled.button`
	background: #ffffff;
	border: none;
	width: 40px;
	height: 40px;
	cursor: pointer;

	&:disabled {
		background: #a3a3a3;
	}

	${IconArrow} {
		font-size: 11px;
		margin-left: 10px;

		&:before,
		&:after {
			background: #000000;
		}
	}
`;

const ScrollRight = styled(ScrollButton)`
	margin-left: 10px;

	${IconArrow} {
		transform: rotate(-88deg);
	}
`;

const ScrollLeft = styled(ScrollButton)`
	${IconArrow} {
		margin-left: -10px;
		transform: rotate(88deg);
	}
`;

const getStateClass = (currentID: number, selectedID: number | null) => {
	if (selectedID === null) {
		return "";
	}

	if (currentID === selectedID) {
		return "active";
	}

	return "fade";
};

export const QuestionGeneral: React.FC<IQuestion> = ({
	title,
	options,
	id: questionID,
	questionNumber,
	image,
	mobileImage,
}) => {
	const {UIQuestionsProgress} = useStore();
	const [selectedID, setSelectedID] = useState<null | number>(null);
	const element = useRef<HTMLDivElement>(null);
	const isMobile = useMediaQuery("(max-width: 768px)");
	const slider = useSlider(element);
	const isSliderVisible = Boolean(!isMobile && slider && slider.is_show_nav);
	const noOfQuestions = UIQuestionsProgress.noOfQuestions;

	return (
		<Wrapper background={image} backgroundMobile={mobileImage}>
			<QuestionContainer>
				<QuestionTitleContainer>
					<QuestionCounter>
						Q{questionNumber}/{noOfQuestions}
					</QuestionCounter>
					<QuestionTitle>{title}</QuestionTitle>
				</QuestionTitleContainer>

				<OptionsList ref={element}>
					{options.map(({id, title, image, mobileImage}) => (
						<Option
							key={id}
							className={getStateClass(id, selectedID)}
							onClick={() => {
								UIQuestionsProgress.selectAnswer(questionID, id);
								setSelectedID(id);
							}}>
							<ImageContainer>
								<Image
									src={`${IMAGES_URL}image/${isMobile ? mobileImage : image}`}
								/>
							</ImageContainer>
							<FigureCaption>{title}</FigureCaption>
						</Option>
					))}
				</OptionsList>
				<Exist when={isSliderVisible}>
					<WrapperScrollNaw>
						<ScrollLeft
							onClick={slider?.scrollLeft}
							disabled={slider?.is_disabled_left}>
							<IconArrow />
						</ScrollLeft>
						<ScrollRight
							onClick={slider?.scrollRight}
							disabled={slider?.is_disabled_right}>
							<IconArrow />
						</ScrollRight>
					</WrapperScrollNaw>
				</Exist>
			</QuestionContainer>
		</Wrapper>
	);
};
