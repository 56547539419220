import {createTheme} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {DefaultTheme} from "styled-components";

const themeObject = {
	color: {
		primary: "#ffffff",
		secondary: "#FF0404",
		tertiary: "#052383",
		quaternary: "#4D4D4D",
		questionButton: "#ffffff",
		questionGeneral: "#000000",
		questionCounter: "#",
		fighterListItem: "#000000",
		fighterListItemPosition: "#4D4D4D33",
		fighterListButton: "#ffffff",
		menu: "#052383",
		activeMenu: "#ffffff",
		footer: "#ffffff",
		footerLink: "#",
	},
	background: {
		primary: "#FF0404",
		secondary: "#052383",
		tertiary: "#4D4D4D",
		quaternary: "#ffffff",
		questionButton: "",
		questionButtonActive: "#FF0404",
		questionGeneral: "#ffffff",
		questionCounter: "#FF0404",
		fighterListItem: "linear-gradient(354.75deg, #e5e5e5 -3.11%, #f5f5f5 96.5%)",
		fighterListItemBorder: "#cccccc",
		fighterListButton: "#052383",
		activeMenu: "#052383",
		menu: "#ffffff",
		form: "#ffffff",
		footer: "#052383",
	},
	button: {
		primary: {
			color: "#ffffff",
			background: "#FF0404",
			borderColor: "#FF0404",
			disabledColor: "#ffffff",
			disabledBackground: "#C7C7CC",
			disabledBorderColor: "#C7C7CC",
			hoverColor: "#ffffff",
			hoverBackground: "#052383",
			hoverBorderColor: "#052383",
			fontWeight: "bold",
		},
		secondary: {
			color: "#FF0404",
			background: "#ffffff",
			borderColor: "#FF0404",
			disabledColor: "#C7C7CC",
			disabledBackground: "#ffffff",
			disabledBorderColor: "#C7C7CC",
			hoverColor: "#052383",
			hoverBackground: "#ffffff",
			hoverBorderColor: "#052383",
			fontWeight: "bold",
		},
	},
	breakpoints: {
		values: {
			small_mobile: 425,
			mobile: 768,
			tablet: 960,
			desktop: 1200,
			large_desktop: 1440,
		},
	},
};

export const theme = createTheme(themeObject) as unknown as DefaultTheme;
type ThemeInterface = typeof themeObject;

/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/naming-convention */

declare module "@mui/material/styles" {
	interface Theme extends ThemeInterface {}

	interface BreakpointOverrides {
		xs: false; // removes the `xs` breakpoint
		sm: false;
		md: false;
		lg: false;
		xl: false;
		small_mobile: true; // adds the `mobile` breakpoint
		mobile: true; // adds the `mobile` breakpoint
		tablet: true;
		desktop: true;
		large_desktop: true;
	}
}

declare module "styled-components" {
	export interface DefaultTheme extends Theme {}
}
