import {action, makeObservable, observable, runInAction} from "mobx";
import {RequestState} from "modules/enums";

export abstract class ApiModel<TAPIResponse> {
	@observable public apiState: RequestState = RequestState.IDLE;
	@observable public error?: string;

	/**
	 * The rule is disabled because it's just a placeholder
	 * when it would be required to override.
	 */
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	protected onApiError(resp: Error) {}
	protected abstract performResponse(resp: TAPIResponse): void;

	protected constructor(
		protected readonly api: <TReturn, TArgs = void>(args?: TArgs) => Promise<TReturn>
	) {
		makeObservable(this);
	}

	@action async request() {
		if (this.apiState === RequestState.Requested) {
			return;
		}

		this.apiState = RequestState.Requested;
		this.error = undefined;

		try {
			const response = await this.api<TAPIResponse>();

			runInAction(() => {
				this.performResponse(response);
				this.apiState = RequestState.Received;
			});
		} catch (err) {
			this.apiState = RequestState.IDLE;
			this.error = (err as Error).message;

			runInAction(() => {
				this.onApiError(err as Error);
			});
		}
	}
}
