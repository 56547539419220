import {RefObject, useEffect, useState} from "react";
import {getElementSize} from "modules/utils";

export const useHasScroll = (ref: RefObject<HTMLDivElement>) => {
	const [hasScroll, setHasScroll] = useState(false);

	useEffect(() => {
		const element = ref.current;

		if (!element) {
			return;
		}

		const children = element.children;
		const {width} = getElementSize(element);

		for (const nodeName in children) {
			const child = children[nodeName];

			if (child instanceof HTMLElement) {
				const childSize = getElementSize(child);
				const allowedPrecision = 30;

				if (Math.round(childSize.width - allowedPrecision) > Math.round(width)) {
					setHasScroll(true);
					return;
				}
			}
		}

		setHasScroll(false);
	}, [ref]);

	return hasScroll;
};
