import React, {useCallback, useMemo, useState} from "react";
import {observer} from "mobx-react";
import styled from "styled-components";
import {get} from "lodash";
import ReactAwesomePlayer from "react-awesome-player";
import {useIsMobile, useStore} from "modules/hooks";
import {IMAGES_URL} from "modules/constants";
import {QuestionContainer, QuestionWrapper} from "components";
import {createConnextraScriptTag} from "modules/utils";

const Wrapper = styled(QuestionWrapper)`
	min-height: auto;
`;

const Container = styled(QuestionContainer)`
	padding: 70px 20px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: 60px 7%;
	}
`;

const MediaWrapper = styled.div`
	width: 100%;
	padding: 30px 0;
	gap: 12px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas:
		"video video"
		"first-article second-article";

	.first-article {
		grid-area: first-article;
	}

	.second-article {
		grid-area: second-article;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		gap: 20px;
		flex-direction: row;
		grid-template-areas:
			"video first-article"
			"video second-article";
	}
`;

const VideoContent = styled.div`
	transition: 0.5s opacity 0.5s;
	position: absolute;
	z-index: 1;
	left: 16px;
	bottom: 7px;
	width: calc(100% - 32px);

	${({theme}) => theme.breakpoints.up("mobile")} {
		left: 22px;
		bottom: 20px;
		width: calc(100% - 44px);
	}
`;

const MediaItem = styled.div`
	background: #000000;
	position: relative;
	width: 100%;

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.1) 15%,
			rgba(0, 0, 0, 0.4) 48.44%,
			rgba(0, 0, 0, 0.6) 60%
		);
		height: 70%;
		width: 100%;
	}

	&.hidden {
		&:after {
			content: none;
		}

		${VideoContent} {
			opacity: 0;
			pointer-events: none;
		}
	}
`;

const MediaVideo = styled(MediaItem)`
	grid-area: video;

	.react-awesome-player-container {
		video {
			background: #1c1c1c;
		}

		.vjs-big-play-button {
			background: ${({theme}) => theme.background.primary};
		}

		&:hover .vjs-big-play-button {
			background: ${({theme}) => theme.background.primary};
		}
	}
`;

const VideoDesc = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 700;
	font-size: 20px;
	line-height: 130%;
	letter-spacing: 0.03em;
	max-width: 80%;
	text-shadow: rgb(0 0 0 / 50%) 1px 0px 2px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		font-size: 44px;
	}
`;

const ArticleDesc = styled.div`
	display: flex;
	flex-direction: column;
	font-weight: 700;
	font-size: 20px;
	line-height: 100%;
	letter-spacing: 0.03em;
	word-break: break-word;
	max-width: 80%;
	text-shadow: rgb(0 0 0 / 50%) 1px 0px 2px;

	${({theme}) => theme.breakpoints.up("tablet")} {
		font-size: 32px;
	}
`;

const Image = styled.img`
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: cover;
`;

const MediaArticleContent = styled.div`
	left: 12px;
	bottom: 14px;
	position: absolute;
	z-index: 1;

	${({theme}) => theme.breakpoints.up("mobile")} {
		bottom: 19px;
		left: 30px;
	}
`;

export const CTA: React.FC = observer(() => {
	const {UIFighterList} = useStore();
	const {isMobile} = useIsMobile();
	const [isVideoPlaying, setVideoPlaying] = useState(false);
	const topFighter = UIFighterList.topFighter;
	const title = get(topFighter, "adMainTitle", "");
	const adMainImg = isMobile
		? get(topFighter, "adMainImgMobile", "")
		: get(topFighter, "adMainImg", "");
	const adMainVideo = get(topFighter, "adMainVideo", "");
	const adFirstBtnLink = get(topFighter, "adFirstBtnLink", "");
	const adFirstTitle = get(topFighter, "adFirstTitle", "");
	const adFirstImg = isMobile
		? get(topFighter, "adFirstImgMobile", "")
		: get(topFighter, "adFirstImg", "");
	const adSecondBtnLink = get(topFighter, "adSecondBtnLink", "");
	const adSecondImg = isMobile
		? get(topFighter, "adSecondImgMobile", "")
		: get(topFighter, "adSecondImg", "");
	const adSecondTitle = get(topFighter, "adSecondTitle", "");

	const startPlaying = useCallback(() => {
		setVideoPlaying(true);
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_fighterintrovideoclick"
		);
	}, [setVideoPlaying]);

	const adFirstClickHandler = useCallback(() => {
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_fighterprofileclick"
		);
	}, []);

	const adSecondClickHandler = useCallback(() => {
		createConnextraScriptTag(
			"https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_fighterfullvideoclick"
		);
	}, []);

	const videoOptions = useMemo(
		() => ({
			poster: `${IMAGES_URL}image/${adMainImg}`,
			sources: [
				{
					type: "video/mp4",
					src: `${IMAGES_URL}image/${adMainVideo}`,
				},
			],
		}),
		[adMainVideo, adMainImg]
	);

	return (
		<Wrapper>
			<Container>
				<MediaWrapper>
					<MediaVideo className={isVideoPlaying ? "hidden" : ""}>
						<ReactAwesomePlayer play={startPlaying} options={videoOptions} />
						<VideoContent>
							<VideoDesc>{title}</VideoDesc>
						</VideoContent>
					</MediaVideo>

					<MediaItem
						as="a"
						target="_blank"
						rel="nofollow noreferrer"
						href={adFirstBtnLink}
						className="first-article"
						onClick={adFirstClickHandler}>
						<Image src={`${IMAGES_URL}image/${adFirstImg}`} />
						<MediaArticleContent>
							<ArticleDesc>{adFirstTitle}</ArticleDesc>
						</MediaArticleContent>
					</MediaItem>

					<MediaItem
						as="a"
						target="_blank"
						rel="nofollow noreferrer"
						href={adSecondBtnLink}
						className="second-article"
						onClick={adSecondClickHandler}>
						<Image src={`${IMAGES_URL}image/${adSecondImg}`} />
						<MediaArticleContent>
							<ArticleDesc>{adSecondTitle}</ArticleDesc>
						</MediaArticleContent>
					</MediaItem>
				</MediaWrapper>
			</Container>
		</Wrapper>
	);
});
