import {action, computed, makeObservable, observable, values} from "mobx";
import {RootStore} from "modules/stores/RootStore";
import {filter, findIndex, isEmpty, isUndefined, keyBy, size} from "lodash";
import {Api, IAnswersPayload} from "modules/utils/Api";
import {RequestState} from "modules/enums";
import {createConnextraScriptTag} from "modules/utils";

export class UIQuestionsProgress<TModel extends RootStore> {
	registrationScreenID = Number.MAX_SAFE_INTEGER;
	resultScreenID = Number.MAX_SAFE_INTEGER - 1;
	CTAScreenID = Number.MAX_SAFE_INTEGER - 2;

	@observable isMenuOpen: boolean = false;
	@observable answers: Record<string, number> = {};
	@observable viewedQuestionID?: number;
	@observable activeQuestionIndex: number = 0;
	@observable apiState: RequestState = RequestState.IDLE;
	@observable errors: Record<string, string> = {};
	@observable isShowResultPage: boolean = false;
	@observable isSubmittedForm: boolean = false;

	constructor(private readonly rootStore: Pick<TModel, "questions" | "fingerprint">) {
		makeObservable(this);
	}

	@action toggleMenu(isOpen?: boolean) {
		if (isOpen !== undefined) {
			this.isMenuOpen = isOpen;
		} else {
			this.isMenuOpen = !this.isMenuOpen;
		}
	}

	@computed get allItems() {
		const questions = this.rootStore.questions.entities;

		const activeIndex = this.activeQuestionIndex;

		return questions.map((question, index) => {
			const hasAnswer = !isUndefined(this.answers[question.id]);
			const isActive = activeIndex === index;
			const questionNumber = index + 1;

			return {hasAnswer, isActive, questionNumber, ...question};
		});
	}

	@computed get isEmpty() {
		return isEmpty(this.allItems);
	}

	@computed get noOfQuestions() {
		return size(this.allItems);
	}

	@computed get allItemsByID() {
		return keyBy(this.allItems, "id");
	}

	@computed get progressItems() {
		const maxIndex = size(filter(this.allItems, {hasAnswer: true}));
		return this.allItems.filter((_, index) => index <= maxIndex);
	}

	@computed get isAllQuestionsAnswered() {
		return !isEmpty(this.allItems) && this.allItems.every(({hasAnswer}) => hasAnswer);
	}

	@computed get isScrollCTAVisibleToTop() {
		return false;
	}

	@computed get isAnyQuestionAnswered() {
		return !isEmpty(this.allItems) && this.allItems.some(({hasAnswer}) => hasAnswer);
	}

	@computed get isScrollCTAVisible() {
		return Boolean(this.viewedQuestionID && this.answers[this.viewedQuestionID]);
	}

	@computed get elementsClassName() {
		let className = "";

		if (this.viewedQuestionID === this.registrationScreenID) {
			className += " blue";
		}

		if (this.viewedQuestionID === this.resultScreenID) {
			className += " blue";
		}

		if (this.viewedQuestionID === this.CTAScreenID) {
			className += " blue";
		}

		return className;
	}

	@computed
	private get nextQuestionID() {
		const nextQuestionIndex = findIndex(this.allItems, {id: this.viewedQuestionID}) + 1;
		if (nextQuestionIndex > 0 && this.allItems[nextQuestionIndex]?.id) {
			return this.allItems[nextQuestionIndex]?.id;
		}

		return this.registrationScreenID;
	}

	scrollToNextScreen(id?: number) {
		document.getElementById(`question-${id || this.nextQuestionID}`)?.scrollIntoView({
			block: "start",
			behavior: "smooth",
		});
	}

	@action selectAnswer(questionID: number, optionID: number) {
		const currentQuestionIndex = findIndex(this.allItems, {id: questionID});
		const nextQuestionIndex = currentQuestionIndex + 1;

		if (!this.activeQuestionIndex || this.activeQuestionIndex < nextQuestionIndex) {
			this.activeQuestionIndex = nextQuestionIndex;
		}

		if (this.answers[questionID] !== optionID) {
			this.answers[questionID] = optionID;
			setTimeout(() => this.scrollToNextScreen(), 500);
		}

		this.isSubmittedForm = false;
		this.isShowResultPage = false;
	}

	@action reportError(errors: Record<string, string>) {
		this.errors = errors;

		return true;
	}

	@action clearErrors() {
		this.errors = {};
	}

	async saveAnswers(params: Omit<IAnswersPayload, "answers" | "user_key">) {
		if (this.apiState === RequestState.Requested) {
			return;
		}

		const payload: IAnswersPayload = {
			...params,
			user_key: this.rootStore.fingerprint.visitorId,
			answers: values(this.answers) as number[],
		};

		this.apiState = RequestState.Requested;

		try {
			await Api.Answers.save(payload);
			this.isShowResultPage = true;
			this.isSubmittedForm = true;
			setTimeout(() => this.scrollToNextScreen(this.resultScreenID), 500);

			createConnextraScriptTag(
				`https://us.connextra.com/dcs/tagController/tag/64287d52a49d/f2p_quiz_submission?AccountID=${this.rootStore.fingerprint.visitorId}`
			);
		} catch (err) {
			this.errors = {"": (err as Error).message};
		} finally {
			this.apiState = RequestState.Received;
		}
	}

	@action setViewedQuestion(questionID: number) {
		this.viewedQuestionID = questionID;
	}

	@action resetProgress() {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: "smooth",
		});

		this.answers = {};
		this.isShowResultPage = false;
		this.isSubmittedForm = false;
		this.apiState = RequestState.IDLE;
	}
}
