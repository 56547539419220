import React, {useCallback} from "react";
import styled from "styled-components";
import {observer} from "mobx-react";
import {size} from "lodash";
import {useStore} from "modules/hooks";
import {HamburgerButton} from "./HamburgerButton";
import {MenuItem} from "./MenuItem";

const Wrapper = styled.div`
	position: fixed;
	left: 0;
	top: 0;
	z-index: 11;
	height: 100vh;
	width: 0;
`;

const MenuBar = styled.div`
	color: ${({theme}) => theme.color.menu};
	background: ${({theme}) => theme.background.menu};
	height: 100%;
	width: 320px;
	left: -400px;
	position: relative;
	z-index: 1;
	transition: all 0.5s;
	overflow: hidden;
	box-shadow: 0 0 5px rgb(0 0 0 / 20%);
	font-family: "Montserrat", sans-serif;

	&.open {
		left: 0;
	}

	@media (min-width: 400px) {
		width: 400px;
	}

	@media (min-width: 600px) {
		width: 500px;
		left: -500px;
	}

	${({theme}) => theme.breakpoints.up("mobile")} {
		width: 600px;
		left: -600px;
	}
`;

const MenuBarInner = styled.div`
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	padding: calc(8vh + 50px) 0 220px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		padding: calc(9vh + 50px) 0 240px;
	}
`;

const StepListScroll = styled.div`
	height: 100%;
	overflow: auto;
`;

const StepList = styled.div`
	display: flex;
	flex-flow: column;
	min-height: 100%;
	justify-content: center;

	/* Hide scrollbar for Chrome, Safari and Opera */

	&::-webkit-scrollbar {
		display: none;
	}

	scrollbar-width: none; /* Firefox */
	-ms-overflow-style: none; /* IE and Edge */
`;

/*const Bottom = styled.div`
	min-width: 200px;
	position: absolute;
	left: 5.6vw;
	bottom: 48px;

	${({theme}) => theme.breakpoints.up("mobile")} {
		bottom: 52px;
	}
`;*/

export const Menu: React.FC = observer(() => {
	const {UIQuestionsProgress, I18n} = useStore();
	const {isMenuOpen} = UIQuestionsProgress;
	const openClassName = isMenuOpen ? "open" : "";

	const closeMenu = useCallback(
		({currentTarget, relatedTarget}: React.MouseEvent<HTMLDivElement>) => {
			if (relatedTarget === window) {
				return;
			}
			if (currentTarget.contains(relatedTarget as Node)) {
				return;
			}

			UIQuestionsProgress.toggleMenu(false);
		},
		[UIQuestionsProgress]
	);

	const toggleMenu = useCallback(() => {
		UIQuestionsProgress.toggleMenu();
	}, [UIQuestionsProgress]);

	const items = UIQuestionsProgress.allItems;
	const isQuizComplete = UIQuestionsProgress.isShowResultPage;
	const finalScreenIndex = size(items);

	const finalScreenData = {
		id: UIQuestionsProgress.resultScreenID,
		shortTitle: I18n.t("menu.items.last.title"),
		hasAnswer: isQuizComplete,
		isActive: isQuizComplete,
		index: finalScreenIndex,
	};

	return (
		<Wrapper onMouseLeave={closeMenu}>
			<HamburgerButton className={openClassName} onClick={toggleMenu} />

			<MenuBar className={openClassName}>
				<MenuBarInner>
					<StepListScroll>
						<StepList>
							{items.map((question, index) => (
								<MenuItem key={question.id} index={index} {...question} />
							))}
							<MenuItem showTitle {...finalScreenData} />
						</StepList>
					</StepListScroll>
					{/*<Bottom>
						<SocialShareLinks />
					</Bottom>*/}
				</MenuBarInner>
			</MenuBar>
		</Wrapper>
	);
});
