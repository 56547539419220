import {FB_SHARE_URL} from "modules/constants";
import {Language, ShareType} from "modules/enums";
import * as Sentry from "@sentry/react";

interface IShareObject {
	t?: string;
	// squad: number;
	lang: Language;
	// squadName: string;
}

export interface IShare {
	sc: ShareType;
	// squadId: number;
	// squadName: string;
}

/**
 * in order to reduce the size of the string for sharing
 */
const last_four_number = -4;
const getTimestamp = () => Date.now().toString().substr(last_four_number);

export const share_messages = {
	[Language.EN]: (/*name: string*/) => {
		return `I discovered my ultimate PFL fighter match! Don't miss out, find your match today!`;
	},
};

const getEncodedJson = (object: IShareObject) => btoa(JSON.stringify(object));

const getLinkForShare = (params: IShareObject) => {
	return `${FB_SHARE_URL}general/${getEncodedJson(params)}`;
};

export const share = (data: IShare): void => {
	const object: IShareObject = {
		t: getTimestamp(),
		lang: Language.EN,
		// squad: data.squadId,
		// squadName: data.squadName,
	};
	console.log(data);

	if (data.sc === ShareType.MOBILE) {
		return void shareNavigator(object);
	}

	if (data.sc === ShareType.TW) {
		return shareTwitter(object);
	}

	if (data.sc === ShareType.FB) {
		return shareFB(object);
	}
};

export const shareFB = (params: IShareObject) => {
	const FB_API = window.FB;
	if (FB_API) {
		FB_API.ui({
			method: "share",
			display: "popup",
			href: getLinkForShare(params),
		});
	}
	else {
		const winWidth = 520;
		const winHeight = 350;
		const winTop = window.screen.height / 2 - winHeight / 2;
		const winLeft = window.screen.width / 2 - winWidth / 2;

		window.open(
			`https://www.facebook.com/sharer/sharer.php?display=popup&method=share&u=${getLinkForShare(params)}`,
			"_blank",
			`top=${winTop},left=${winLeft},width=${winWidth}, height=${winHeight},toolbar=0,status=0,scrollbars=no`
		);
	}
};

export const shareTwitter = (params: IShareObject) => {
	const href = getLinkForShare(params);
	const text = share_messages[params.lang](/*params.squadName*/);

	window.open(
		"https://twitter.com/share?url=" +
			encodeURIComponent(href) +
			"&text=" +
			encodeURIComponent(text),
		"twitter-share-dialog",
		"width=626,height=436"
	);
};

export const shareNavigator = async (params: IShareObject) => {
	try {
		const url = getLinkForShare(params);
		const text = share_messages[params.lang](/*params.squadName*/);

		const shareData = {
			url,
			text,
		};

		await navigator.share(shareData);
	} catch (err) {
		Sentry.captureException(err);
		if (err instanceof Error) {
			console.error(err.message);
		}
	}
};
