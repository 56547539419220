import {makeObservable, observable} from "mobx";
import {Api} from "modules/utils/Api";
import {ApiModel} from "modules/stores/Base/ApiModel";

type TResponse = {code: string; name: string}[];

export class Countries extends ApiModel<TResponse> {
	@observable public data: {code: string; name: string}[] = [];

	constructor() {
		super(Api.JSON.countries);
		makeObservable(this);
	}

	protected performResponse(response: TResponse) {
		this.data = response;
	}
}
